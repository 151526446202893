// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_rw d_fp d_bG d_dv";
export var alignLeft = "v_qf d_fp d_bG d_dv";
export var alignDiscCenter = "v_rx d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_ry d_fr d_bH d_dx";
export var alignRight = "v_qg d_fr d_bH d_dx";
export var footerContainer = "v_rz d_dW d_bW";
export var footerContainerFull = "v_rB d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_rC d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_rD d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_rF d_bz d_bJ d_bN d_bL";
export var wide = "v_rG d_cy";
export var right = "v_rH d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_rJ d_bC d_bP d_bJ";
export var badgeContainer = "v_rK d_bz d_bH d_bP";
export var badge = "v_rL";
export var padding = "v_rM d_c7";
export var end = "v_rN d_bH";
export var linkWrapper = "v_rP d_dB";
export var link = "v_m7 d_dB";
export var colWrapper = "v_rQ d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_rR d_bz d_bJ";
export var media = "v_rS d_bx d_dy";
export var itemRight = "v_rT";
export var itemLeft = "v_rV";
export var itemCenter = "v_rW";
export var exceptionWeight = "v_qK L_v4";