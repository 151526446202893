// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "F_tf d_bD";
export var storyRowWrapper = "F_hx d_hx d_bK";
export var storyLeftWrapper = "F_tg d_bz d_bP";
export var storyWrapperFull = "F_th d_cD";
export var storyWrapperFullLeft = "F_pj d_cD d_bz d_bP d_bD";
export var contentWrapper = "F_m1 d_hy";
export var storyLeftWrapperCenter = "F_tj d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "F_tk d_hF";
export var storyHeader = "F_tl d_hD d_w d_cs";
export var storyHeaderCenter = "F_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "F_hB d_hB d_by d_dw";
export var storyBtnWrapper = "F_tm d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "F_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "F_qy d_fg d_Z";
export var imageWrapperFull = "F_q6 d_w d_H d_bf d_Z";
export var SubtitleSmall = "F_qb L_qb L_vD L_vQ";
export var SubtitleNormal = "F_qc L_qc L_vD L_vR";
export var SubtitleLarge = "F_qd L_qd L_vD L_vS";