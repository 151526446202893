// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "G_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "G_fR d_fR d_bz d_bJ";
export var menuDesign6 = "G_tn d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "G_tp d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "G_tq d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "G_tr d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "G_ts d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "G_mt d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "G_mF d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "G_tt";
export var navbarItem = "G_mv d_bx";
export var navbarLogoItemWrapper = "G_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "G_tv d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "G_tw d_gd d_by d_Z d_bs";
export var burgerToggle = "G_tx d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "G_ty d_gd d_by d_Z d_bs";
export var burgerInput = "G_tz d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "G_tB d_f3 d_w d_H";
export var burgerLine = "G_tC d_f1";
export var burgerMenuDesign6 = "G_tD d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "G_tF d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "G_tG d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "G_tH d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "G_tJ d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "G_tK d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "G_tL d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "G_tM d_bC d_bP";
export var compact = "G_tN";
export var navDivided = "G_tP";
export var staticBurger = "G_tQ";
export var menu = "G_tR";
export var navbarDividedLogo = "G_tS";
export var nav = "G_tT";