// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "r_qL d_bD";
export var datasheetRowWrapper = "r_lt d_lt d_bK";
export var datasheetLeftWrapper = "r_qM d_bz d_bP";
export var datasheetWrapperFull = "r_qN d_cD";
export var datasheetWrapperFullLeft = "r_pc d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "r_m1 d_lv";
export var contentWrapperCenter = "r_qP d_lz";
export var datasheetLeftWrapperCenter = "r_qQ d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "r_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "r_qR d_lw";
export var datasheetFooter = "r_ly d_ly";
export var alignLeft = "r_qf d_dv";
export var alignCenter = "r_bP d_dw";
export var alignRight = "r_qg d_dx";
export var datasheetInnerWrapperNewLeft = "r_qS d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "r_qT d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "r_qV d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "r_qW d_bK";
export var datasheetSubtitle = "r_qX d_cx";
export var tableRow = "r_qY";
export var cell = "r_qZ";
export var tableRowWrapper = "r_q0 d_w d_dv";
export var tableHeadingCell = "r_q1";
export var tableHeading = "r_q2";
export var tableRowStriped = "r_q3";
export var tableRowHeading = "r_q4";
export var dataTable = "r_q5 d_w";
export var imageWrapper = "r_qy d_fg d_Z";
export var imageWrapperFull = "r_q6 d_H d_w d_bf d_Z";
export var imageWrapperIcon = "r_q7";
export var titleMargin = "r_q8 d_cs";
export var datasheetInnerInnerWrapper = "r_q9 d_w";
export var hasLabels = "r_rb";
export var label = "r_rc";
export var SmallSmall = "r_qz L_qz L_vD L_v0";
export var SmallNormal = "r_qB L_qB L_vD L_v1";
export var SmallLarge = "r_qC L_qC L_vD L_vY";