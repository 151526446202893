// extracted by mini-css-extract-plugin
export var alignLeft = "M_qf d_fp d_bG d_dv";
export var alignCenter = "M_bP d_fq d_bD d_dw";
export var alignRight = "M_qg d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "M_wF";
export var wrap = "M_wG d_bJ d_cb";
export var topPadding = "M_wH d_cX";
export var compContent = "M_wJ d_w";
export var last = "M_wK";
export var box = "M_wL d_cr";
export var boxWrapper = "M_wM d_w d_bz";
export var boxWrapperFirst = "M_wN d_w d_bz";