// extracted by mini-css-extract-plugin
export var alignLeft = "y_qf d_bG";
export var alignCenter = "y_bP d_bD";
export var alignRight = "y_qg d_bH";
export var textAlignLeft = "y_sn";
export var textAlignCenter = "y_sp";
export var textAlignRight = "y_sq";
export var hoursInnerWrapperAlt = "y_sr d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "y_ss d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "y_st d_dw";
export var titleMargin = "y_q8 d_cw";
export var hoursInnerInnerWrapper = "y_sv d_cz";