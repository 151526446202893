// extracted by mini-css-extract-plugin
export var carouselContainer = "T_xz d_w d_H d_bf d_Z";
export var carouselContainerCards = "T_xB T_xz d_w d_H d_bf d_Z";
export var carouselContainerSides = "T_xC d_w d_H d_Z";
export var prevCarouselItem = "T_xD d_w d_H d_0 d_k";
export var prevCarouselItemL = "T_xF T_xD d_w d_H d_0 d_k";
export var moveInFromLeft = "T_xG";
export var prevCarouselItemR = "T_xH T_xD d_w d_H d_0 d_k";
export var moveInFromRight = "T_xJ";
export var selectedCarouselItem = "T_xK d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "T_xL T_xK d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "T_xM T_xK d_w d_H d_Z d_bf";
export var nextCarouselItem = "T_xN d_w d_H d_0 d_k";
export var nextCarouselItemL = "T_xP T_xN d_w d_H d_0 d_k";
export var nextCarouselItemR = "T_xQ T_xN d_w d_H d_0 d_k";
export var arrowContainer = "T_xR d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "T_xS T_xR d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "T_xT T_xS T_xR d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "T_xV T_xR d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "T_xW";
export var nextArrowContainerHidden = "T_xX T_xV T_xR d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "T_kG d_0";
export var prevArrow = "T_xY T_kG d_0";
export var nextArrow = "T_xZ T_kG d_0";
export var carouselIndicatorContainer = "T_x0 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "T_x1 d_w d_bz d_bF";
export var carouselText = "T_x2 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "T_x3 T_x2 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "T_x4 d_b7";
export var carouselIndicator = "T_x5 T_x4 d_b7";
export var carouselIndicatorSelected = "T_x6 T_x4 d_b7";
export var arrowsContainerTopRight = "T_x7 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "T_x8 d_0 d_bl d_bC";
export var arrowsContainerSides = "T_x9 d_0 d_bl d_bC";
export var smallArrowsBase = "T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "T_yc T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "T_yd T_yc T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "T_yf T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "T_yg T_yf T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "T_p7";
export var iconSmall = "T_yh";
export var multipleWrapper = "T_yj d_bC d_bF d_bf";
export var multipleImage = "T_yk d_bC";
export var sidesPrevContainer = "T_yl T_yc T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "T_ym T_yc T_yb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";