// extracted by mini-css-extract-plugin
export var socialContainer = "D_s1";
export var instagramLink = "D_s2";
export var socialWrapperLeft = "D_nV d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "D_s3";
export var socialContentContainerFull = "D_s4";
export var instagramContainer = "D_s5";
export var twitterContainer = "D_s6";
export var facebookContainer = "D_s7";
export var socialErrorContainer = "D_s8";
export var facebookContainerWide = "D_s9";
export var twitterContainerWide = "D_tb";
export var socialParagraphCenter = "D_tc";
export var instaWrapper = "D_td";
export var SubtitleSmall = "D_qb L_qb L_vD L_vQ";
export var SubtitleNormal = "D_qc L_qc L_vD L_vR";
export var SubtitleLarge = "D_qd L_qd L_vD L_vS";