// extracted by mini-css-extract-plugin
export var alignLeft = "P_qf d_fp d_bG d_dv";
export var alignCenter = "P_bP d_fq d_bD d_dw";
export var alignRight = "P_qg d_fr d_bH d_dx";
export var verticalSolidComponent = "P_w3";
export var compContent = "P_wJ d_r";
export var normal = "P_w4 d_Z d_cb";
export var reverse = "P_w5 d_Z d_bK";
export var last = "P_wK";
export var empty = "P_w6 d_r";
export var empty2 = "P_w7 d_bw";
export var borderRight = "P_w8 d_b8 d_c1";
export var borderLeft = "P_w9 d_b9 d_c1";