// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_r4 d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_r5 d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_r6 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_r7 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_r8 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_r9 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_sb d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_sc L_sc";
export var heroExceptionNormal = "x_sd L_sd";
export var heroExceptionLarge = "x_sf L_sf";
export var Title1Small = "x_sg L_sg L_vD L_vF";
export var Title1Normal = "x_sh L_sh L_vD L_vG";
export var Title1Large = "x_sj L_sj L_vD L_vH";
export var BodySmall = "x_sk L_sk L_vD L_vX";
export var BodyNormal = "x_sl L_sl L_vD L_vY";
export var BodyLarge = "x_sm L_sm L_vD L_vZ";