// extracted by mini-css-extract-plugin
export var alignLeft = "B_qf d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qg d_fr d_bH d_dx";
export var menuContainer = "B_sD d_dW";
export var menuContainerFull = "B_sF d_dT";
export var menuMainHeader = "B_jC d_jC d_w d_c3";
export var menuComponentWrapper = "B_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "B_jH d_jH d_cy";
export var menuComponentText = "B_jD d_jD d_cn";
export var menuComponentTextDesign2 = "B_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "B_jJ d_jJ d_w d_Z";